import {
  CREATE_PRODUCT_GIFT_CARD,
  CREATE_PRODUCT_GIFT_CARD_FAILED,
  CREATE_PRODUCT_GIFT_CARD_SUCCESS,
  DELETE_PRODUCT_GIFT_CARD, DELETE_PRODUCT_GIFT_CARD_FAILED, DELETE_PRODUCT_GIFT_CARD_SUCCESS,
  FETCH_PRODUCT_GIFT_CARD,
  FETCH_PRODUCT_GIFT_CARD_FAILED,
  FETCH_PRODUCT_GIFT_CARD_SUCCESS,
  INIT_PRODUCT_GIFT_CARD,
  UPDATE_PRODUCT_GIFT_CARD,
  UPDATE_PRODUCT_GIFT_CARD_FAILED,
  UPDATE_PRODUCT_GIFT_CARD_SUCCESS,
  ASSIGN_PRODUCT_GIFT_CARD
} from "redux/model/productGiftCards/productGiftCard/constants";

export const initProductGiftCard = () => ({
  type: INIT_PRODUCT_GIFT_CARD,
  payload: {}
});

export const createProductGiftCard = ({ productGiftCard }) => ({
  type: CREATE_PRODUCT_GIFT_CARD,
  payload: { productGiftCard }
});

export const createProductGiftCardSuccess = ({ productGiftCard }) => ({
  type: CREATE_PRODUCT_GIFT_CARD_SUCCESS,
  payload: { productGiftCard }
});

export const createProductGiftCardFailed = ({ error }) => ({
  type: CREATE_PRODUCT_GIFT_CARD_FAILED,
  payload: { error }
});

export const fetchProductGiftCard = ({ productGiftCardId }) => ({
  type: FETCH_PRODUCT_GIFT_CARD,
  payload: { productGiftCardId }
});

export const fetchProductGiftCardSuccess = ({ productGiftCard }) => ({
  type: FETCH_PRODUCT_GIFT_CARD_SUCCESS,
  payload: { productGiftCard }
});

export const fetchProductGiftCardFailed = ({ error }) => ({
  type: FETCH_PRODUCT_GIFT_CARD_FAILED,
  payload: { error }
});

export const updateProductGiftCard = ({ productGiftCard }) => ({
  type: UPDATE_PRODUCT_GIFT_CARD,
  payload: { productGiftCard }
});

export const updateProductGiftCardSuccess = ({ productGiftCard }) => ({
  type: UPDATE_PRODUCT_GIFT_CARD_SUCCESS,
  payload: { productGiftCard }
});

export const updateProductGiftCardFailed = ({ error }) => ({
  type: UPDATE_PRODUCT_GIFT_CARD_FAILED,
  payload: { error }
});

export const deleteProductGiftCard = ({ productGiftCard }) => ({
  type: DELETE_PRODUCT_GIFT_CARD,
  payload: { productGiftCard }
});

export const deleteProductGiftCardSuccess = ({ productGiftCard }) => ({
  type: DELETE_PRODUCT_GIFT_CARD_SUCCESS,
  payload: { productGiftCard }
});

export const deleteProductGiftCardFailed = ({ error }) => ({
  type: DELETE_PRODUCT_GIFT_CARD_FAILED,
  payload: { error }
});

export const assignProductGiftCard = ({ fields }) => ({
  type: ASSIGN_PRODUCT_GIFT_CARD,
  payload: { fields }
});
