import {
  FETCH_GIFT_CARD,
  FETCH_GIFT_CARD_FAILED,
  FETCH_GIFT_CARD_SUCCESS,
  INIT_GIFT_CARD,
  ASSIGN_GIFT_CARD
} from "redux/model/giftCards/giftCard/constants";

const INIT_STATE = {
  giftCard: {},
  loading: true,
  error: null,
};

const giftCardReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_GIFT_CARD:
      return INIT_STATE;

    case FETCH_GIFT_CARD:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GIFT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        giftCard: action.payload.giftCard,
      };

    case FETCH_GIFT_CARD_FAILED:
      return {
        ...state,
        giftCard: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_GIFT_CARD:
      return {
        ...state,
        giftCard: {
          ...state.giftCard,
          ...action.payload.fields,
        }
      }

    default:
      return { ...state };
  }
};

export default giftCardReducers;
